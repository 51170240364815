import Vue from "vue";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import VueSocialSharing from 'vue-social-sharing'
import VueMeta from 'vue-meta'
import Vuelidate from 'vuelidate'
import VueSweetalert2 from 'vue-sweetalert2';

import App from "./App.vue";
import router from './router'
// Import Bootstrap and BootstrapVue CSS files (order is important)
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import 'sweetalert2/dist/sweetalert2.min.css';

import "./assets/css/app.css";
import "./assets/css/fonts.css";

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);
Vue.use(VueSocialSharing);
Vue.use(VueMeta)
Vue.use(Vuelidate)
Vue.use(VueSweetalert2);

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
